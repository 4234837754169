body {
  background-color: #282C34;
}
.App {
  text-align: center;
  max-width: 728px;
  margin: 0 auto;
}
.App header {
  background-color: #ffffff;
  height: 10vh;
  min-height: 50px;
  color: rgb(10, 10, 10);
  position: fixed;
  width: 100%;
  max-width: 728px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
}
.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(40, 37, 53);
}
main {
  padding: 10px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
main::-webkit-scrollbar {
  width: 0.25rem;
}
main::-webkit-scrollbar-track {
  background: #1E1E24;
}
main::-webkit-scrollbar-thumb {
  background: #6649B8;
}
form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: rgb(24, 23, 23);
  width: 100%;
  max-width: 728px;
  display: flex;
  font-size: 1.5rem;
}
form button {
  width: 20%;
  background-color: #1877f2
}
input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: rgb(255, 255, 255);
  color: black;
  outline: none;
  border: none;
  padding: 0 10px;
}
button {
  background-color: #1877f2; /* Green */
  border: none;
  color: rgb(255, 255, 255);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.sign-in {
  color: #282C34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}
ul,
li {
  text-align: left;
  list-style: none;
}
p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}
.message {
  display: flex;
  align-items: center;
}
.sent {
  flex-direction: row-reverse;
}
.sent p {
  color: white;
  background: #0B93F6;
  align-self: flex-end;
}
.received p {
  background: #E5E5EA;
  color: black;
}
img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}